<template>
  <div>
    <v-dialog
      v-model="dialogCompleteUserRegistration"
      persistent
      width="350"
    >
      <v-card
        class="text-center mx-auto"
      >
        <v-card-text>
          <h3 class="text-h6 mt-0 mb-5">
            Complete seu cadastro
          </h3>

          <validation-observer
            ref="formConfirmAccount"
            autocomplete="off"
            tag="form"
            @keyup.enter="fetchEditUser"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="CPF/CNPJ"
                  rules="required"
                  vid="document"
                >
                  <v-text-field
                    v-model="document"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                    label="CPF/CNPJ"
                    dense
                    outlined
                  />
                </validation-provider>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="Telefone"
                  rules="required"
                  vid="phone"
                >
                  <v-text-field
                    v-model="phone"
                    v-mask="['(##) ####-####', '(##) #####-####']"
                    :error-messages="errors"
                    :hide-details="errors.length === 0"
                    label="Telefone"
                    outlined
                    dense
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </validation-observer>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="fetchEditUser"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogConfirmationCode"
      persistent
      width="500"
    >
      <v-card
        class="py-6 px-8 text-center mx-auto"
      >
        <h3 class="text-h6 mb-4">
          Verifique sua conta
        </h3>

        <div class="text-body-2">
          Enviamos um <b>SMS</b> para seu telefone. <br>
          Verifique seu telefone e cole o código abaixo.
        </div>

        <v-sheet class="mt-5">
          <v-otp-input
            v-model="confirmationCode"
            variant="solo"
            @finish="fetchConfirmAccount"
          />
        </v-sheet>

        <v-btn
          class="mt-5 mb-2"
          color="primary"
          height="40"
          variant="flat"
          width="70%"
          @click="fetchConfirmAccount"
        >
          Confirmar
        </v-btn>

        <div class="text-caption">
          Não recebeu o código?
          <a
            :disabled="timeToResendSMS > 0"
            :class="timeToResendSMS > 0 ? 'black--text' : 'info--text'"
            @click="fetchSendSMS"
          >
            Reenviar ({{ timeToResendSMS }}s)
          </a>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ref } from '@vue/composition-api'
import { VOtpInput } from 'vuetify/lib'

export default {
  components: { VOtpInput },
  setup() {
    const user = ref(null)
    const dialogCompleteUserRegistration = ref(false)
    const dialogConfirmationCode = ref(false)
    const confirmationCode = ref(null)
    const document = ref(null)
    const phone = ref(null)
    const timeToResendSMS = ref(30)

    return {
      user,
      dialogCompleteUserRegistration,
      dialogConfirmationCode,
      confirmationCode,
      document,
      phone,
      timeToResendSMS,
    }
  },

  async created() {
    const accountStatus = await this.accountStatus()
    this.dialogCompleteUserRegistration = !accountStatus.status
    this.$emit('accountStatus', accountStatus.status)

    await this.fetchFindUser()
  },

  methods: {
    ...mapActions('userAccount', [
      'accountStatus',
      'sendSMS',
      'confirmAccount',
    ]),

    ...mapActions('user', {
      findUser: 'findById',
      updateUser: 'update',
    }),

    calculateTimeToResend() {
      this.timeToResendSMS = 30

      const interval = setInterval(() => {
        if (this.timeToResendSMS > 0) {
          this.timeToResendSMS -= 1
        } else {
          clearInterval(interval)
        }
      }, 1000)
    },

    async fetchFindUser() {
      const loggedInUserId = localStorage.getItem('id')
      this.user = await this.findUser(loggedInUserId)
    },

    async fetchEditUser() {
      const isValid = await this.$refs.formConfirmAccount.validate()

      if (isValid) {
        const document = this.document.replace(/\D/g, '')

        if (!this.$cpfCnpj.isValid(document)) {
          this.$notify('CPF/CNPJ inválido.', 'error')

          return
        }

        const res = await this.updateUser({
          document,
          phone: this.phone.replace(/\D/g, ''),
          name: this.user.name,
          email: this.user.email,
          customerLevelId: this.user.customerLevelId,
          role: this.user.role,
        })

        if (!String(res).includes('Error')) {
          this.dialogCompleteUserRegistration = false
          this.dialogConfirmationCode = true

          await this.sendSMS()
          this.calculateTimeToResend()
        }
      }
    },

    async fetchSendSMS() {
      this.confirmationCode = null
      if (this.timeToResendSMS > 0) return

      this.calculateTimeToResend()
      await this.sendSMS()
    },

    async fetchConfirmAccount() {
      const res = await this.confirmAccount(this.confirmationCode)

      if (!String(res).includes('Error')) {
        this.dialogConfirmationCode = false
        this.$emit('finish')
        this.$notify('Cadastro confirmado com sucesso.', 'success')
      }
    },
  },
}
</script>
